/*  Copyright 2018 - 2021 Swiss Federal Institute of Technology Lausanne (EPFL)
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *  http://www.apache.org/licenses/LICENSE-2.0.
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 *
 *  This open source software code was developed in part or in whole in the
 *  Human Brain Project, funded from the European Union's Horizon 2020
 *  Framework Programme for Research and Innovation under
 *  Specific Grant Agreements No. 720270, No. 785907, and No. 945539
 *  (Human Brain Project SGA1, SGA2 and SGA3).
 *
 *
 *   Licensed under the Apache License, Version 2.0 (the "License");
 *   you may not use this file except in compliance with the License.
 *   You may obtain a copy of the License at
 *
 *       http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 *
 */
import { AxiosInstance, AxiosRequestConfig } from "axios";
import API from "./API";
import { ContainerStat, Bucket, SwitftObjectOrDir, Permalink, CopyBucketResponse, TempUrlResponse, Settings} from "../types";

const PAGE_SIZE = 50;
const RELATIVE_ROOT_PATH = "/api";

const getContainerUrl = (endpoint: string, containerName: string, prefix?: string): string =>
    `${location.origin}${RELATIVE_ROOT_PATH}/v1/${endpoint}/${containerName}` + (prefix?`?prefix=${prefix}`:"");

const endpoints = {
  settings: () => 
    `${RELATIVE_ROOT_PATH}/settings`,
  fetchContainer: (endpoint: string, containerName: string) => 
    `${RELATIVE_ROOT_PATH}/v1/${endpoint}/${containerName}`,
  getObjectActionUrl: (endpoint: string, containerName: string, objectName: string, storage='cscs' ) =>
    `${RELATIVE_ROOT_PATH}/v1/${endpoint}/${containerName}/${objectName}?redirect=false&storage=${storage}`,
  getUploadFileUrl: (endpoint: string, containerName: string, objectName: string) =>
      `${RELATIVE_ROOT_PATH}/v1/${endpoint}/upload/${containerName}/${objectName}`,
  deleteObject: (endpoint: string, containerName: string, objectName: string) => 
    `${RELATIVE_ROOT_PATH}/v1/${endpoint}/${containerName}/${objectName}`,
  initBucket: (bucketName: string) => 
    `${RELATIVE_ROOT_PATH}/v1/buckets/${bucketName}/init`,
  listBuckets: () => `${RELATIVE_ROOT_PATH}/v1/buckets`,
  getStat: (endpoint: string, containerName: string) => 
    `${RELATIVE_ROOT_PATH}/v1/${endpoint}/${containerName}/stat`,
  createPermalink: () => `${RELATIVE_ROOT_PATH}/permalinks`,
  getPermalinks: (bucket: string, objectName: string) => `${RELATIVE_ROOT_PATH}/permalinks?bucket_name=${bucket}&object_name=${objectName}`,
  permalink: (permalinkId: string)=> `${RELATIVE_ROOT_PATH}/permalinks/${permalinkId}`,
  requestDatasetAccess: (datasetId: string) => 
    `${RELATIVE_ROOT_PATH}/v1/datasets/${datasetId}`,
  copyBucket: (bucketName: string, destinationBucket: string) => 
    `${RELATIVE_ROOT_PATH}/v1/buckets/${bucketName}/copy?to=${destinationBucket}`,
  setPublic: (bucketName: string) => 
    `${RELATIVE_ROOT_PATH}/v1/buckets/${bucketName}`,
  copyObject: (endpoint: string, containerName: string, objectName: string, newObjectName: string) =>
    `${RELATIVE_ROOT_PATH}/v1/${endpoint}/${containerName}/${encodeURIComponent(objectName)}/copy?name=${encodeURIComponent(newObjectName)}`,
  renameObject: (endpoint: string, containerName: string, objectName: string) =>
      `${RELATIVE_ROOT_PATH}/v1/${endpoint}/${containerName}/${encodeURIComponent(objectName)}`
};

interface BucketResponse {
  objects: SwitftObjectOrDir[];
  container: string;
  prefix?: string;
  delimiter?: string;
  marker?: string;
}

class APIBackendAdapter implements API {
  private _axios: AxiosInstance;
  private _dataServiceUrl: string|undefined;
  private _searchUrl: string|undefined;

  constructor(axios: AxiosInstance) {
    this._axios = axios;
  }
  
  setDataServiceUrl(url: string|undefined):void {
    this._dataServiceUrl = url;
  }

  setSearchUrl(url: string|undefined):void {
    this._searchUrl = url;
  }

  get searchUrl() {
    return this._searchUrl;
  }

  private async innerFetch(
    endpoint: string,
    containerName: string,
    previousFiles: SwitftObjectOrDir[],
    prefix: string,
    marker?: string,
    delimiter?: string
  ): Promise<SwitftObjectOrDir[]> {
    const requestOptions: AxiosRequestConfig = {
      params: {
        prefix: prefix,
        marker: marker,
        delimiter: delimiter,
        limit: PAGE_SIZE
      }
    };

    const url = endpoints.fetchContainer(endpoint, containerName);
    const { data } = await this._axios.get(url, requestOptions);
    const bucketResponse = data as BucketResponse;
    if (bucketResponse.objects.length === PAGE_SIZE) {
      const last = bucketResponse.objects[bucketResponse.objects.length - 1];
      if ('subdir' in last) {
        return this.innerFetch(
          endpoint,
          containerName,
          [...previousFiles, ...bucketResponse.objects],
          prefix,
          last.subdir,
          delimiter
        );
      }
      return this.innerFetch(
        endpoint,
        containerName,
        [...previousFiles, ...bucketResponse.objects],
        prefix,
        last.name,
        delimiter
      );
    }
    return [...previousFiles, ...bucketResponse.objects];
  }

  async getUploadUrl(filename: string, bucket: string): Promise<string> {
    const url = endpoints.getObjectActionUrl('buckets', bucket, filename);
    const uploadUrlRes = await this._axios.put(url);
    const uploadUrl = uploadUrlRes.data as TempUrlResponse;
    return uploadUrl.url;
  }

  async getUploadFileUrl(filename: string, bucket: string, file: File): Promise<number> {
    const url = endpoints.getUploadFileUrl('buckets', bucket, filename);
    const formData = new FormData();
    formData.append('file', file);
    const response = await this._axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.status !== 200) {
      throw new Error('Failed to upload file');
    }

    return response.status;
  }

  async fetchContainer(
    endpoint: string,
    containerName: string,
    prefix: string,
    marker?: string,
    delimiter?: string
  ): Promise<SwitftObjectOrDir[]> {
    return this.innerFetch(
      endpoint,
      containerName,
      [],
      prefix,
      marker,
      delimiter
    );
  }

  async getSettings(): Promise<Settings> {
    const { data } = await this._axios.get(endpoints.settings());
    return data as Settings;
  }

  async createPermalink(objectName: string, bucket: string, description: string): Promise<Permalink> {
    const payload = {
      object_name: objectName,
      bucket_name: bucket,
      description: description
    };
    const { data } = await this._axios.post(endpoints.createPermalink(), payload);
    return data as Permalink;
  }

  async revokePermalink(permalinkId: string): Promise<void> {
    await this._axios.delete(endpoints.permalink(permalinkId));
  }

  async updatePermalinks(permalinkId: string, payload: unknown): Promise<Permalink> {
    const { data } = await this._axios.put(endpoints.permalink(permalinkId), payload);
    return data as Permalink;
  }

  async getPermalinks(bucket: string, objectName: string): Promise<Permalink[]> { 
    const { data } = await this._axios.get(endpoints.getPermalinks(bucket, objectName));
    return data as Permalink[];
  }

  async getDownloadUrl(
    endpoint: string,
    containerName: string,
    objectName: string,
    storage = 'cscs'
  ): Promise<TempUrlResponse> {
    const url = endpoints.getObjectActionUrl(
      endpoint,
      containerName,
      objectName,
      storage
    );
    const { data } = await this._axios.get(url);
    return data as TempUrlResponse;
  }

  async deleteObject(
    endpoint: string,
    containerName: string,
    objectName: string
  ): Promise<void > {
    const url = endpoints.deleteObject(endpoint, containerName, objectName);
    await this._axios.delete(url);
  }

  async initBucket(bucketName: string): Promise<void> {
    const url = endpoints.initBucket(bucketName);
    await this._axios.post(url);
  }

  async listBuckets(): Promise<Bucket[]> {
    const { data } = await this._axios.get(endpoints.listBuckets());
    return data as Bucket[];
  }

  async getStat(
    endpoint: string,
    containerName: string
  ): Promise<ContainerStat> {
    const url = endpoints.getStat(endpoint, containerName);
    const { data } = await this._axios.get(url);
    return data as ContainerStat;
  }

  async requestDatasetAccess(
    datasetId: string
  ): Promise<void> {
    const requestOptions: AxiosRequestConfig = {
      params: {
        redirect_uri: `${location.origin}/datasets/${datasetId}`
      }
    };
    const url = endpoints.requestDatasetAccess(datasetId);
    await this._axios.post(url, undefined, requestOptions);
  }

  async copyBucket(
    bucketName: string,
    destinationBucket: string
  ): Promise<CopyBucketResponse> {
    const url = endpoints.copyBucket(bucketName, destinationBucket);
    const { data } = await this._axios.put(url);
    return data as CopyBucketResponse;
  }

  async setPublic(
    bucketName: string,
    isPublic: boolean
  ): Promise<ContainerStat> {
    const url = endpoints.setPublic(bucketName);
    const payload = { is_public: isPublic };
    const { data } = await this._axios.put(url, payload);
    return data as ContainerStat;
  }

  async copyObject(
    endpoint: string,
    containerName: string,
    objectName: string,
    destinationObjectName: string
  ): Promise<void> {
    let newObjectName = destinationObjectName;
    if (objectName.endsWith('/')) {
      newObjectName = destinationObjectName.endsWith('/')
        ? destinationObjectName
        : `${destinationObjectName}/`;
    }
    const url = endpoints.copyObject(
      endpoint,
      containerName,
      objectName,
      newObjectName
    );
    await this._axios.put(url);
  }

  async renameObject(
    endpoint: string,
    containerName: string,
    objectName: string,
    destinationObjectName: string
  ): Promise<void> {
    const requestBody = {
      rename: {
        original_name: objectName,
        target_name: destinationObjectName
      }
    };
    const url = endpoints.renameObject(endpoint,containerName, objectName);
    await this._axios.patch(url, requestBody);
  }

  getZipUrl(endpoint: string, containerName: string, prefix?: string): string {
    const containerUrl = getContainerUrl(endpoint, containerName, prefix);
    return `${this._dataServiceUrl}/zip?container=${encodeURIComponent(containerUrl)}`;
  }
}

export default APIBackendAdapter;
